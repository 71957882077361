<template>
  <el-button
    :color="color"
    :disabled="disabled"
    :type="type"
    :native-type="nativeType"
    :loading="loading"
    :plain="plain"
    :icon="icon"
    :size="size"
    :circle="circle"
    @click="emits('click', $event)"
  >
    <slot />
  </el-button>
</template>

<script setup>
defineProps({
  type: {
    type: String,
    default: 'primary'
  },
  nativeType: {
    type: String,
    default: 'button'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  icon: {
    type: [String, Object, Function],
    required: false,
    default: null
  },
  loading: {
    type: Boolean,
    default: false
  },
  plain: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'default'
  },
  color: {
    type: String,
    default: null
  },
  circle: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['click'])
</script>

<style scoped></style>
